import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import ArticleLayout from "../layouts/articleLayout"
import SEO from "../components/seo"
import LinkTracker from "../components/linkTracker"
import Script from "../components/Script"

const shortcodes = {
  LinkTracker,
  Script,
  Link,
}

// This template is used in the generation of pages in `gatsby-node.js`
const PageTemplate = ({ data: { file } }) => {
  const content = file.frontmatter
  const {
    title,
    headerImage,
    fbTwitterImage,
    linkedInImage,
    linkedInImageSml,
    article,
    intro,
    articleDate,
  } = content
  const type = article ? true : null
  let { headline } = content
  const { body } = file
  headline = headline ? (headline = headline) : (headline = title)
  const metaDate = {
    property: `article:published_time`,
    content: new Date(articleDate).toISOString(),
  }

  return (
    <ArticleLayout headerImg={headerImage}>
      <SEO
        title={title}
        fbTwitterImg={fbTwitterImage ? getSrc(fbTwitterImage) : null}
        linkedInImg={linkedInImage ? getSrc(linkedInImage) : null}
        linkedInImgSml={linkedInImageSml ? getSrc(linkedInImageSml) : null}
        article={type}
        description={intro}
        image={!!headerImage}
        meta={articleDate ? metaDate : {}}
      />
      <h1 className="entry-title">{headline}</h1>
      <div className="u-mb+">
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={content}>{body}</MDXRenderer>
        </MDXProvider>
      </div>
    </ArticleLayout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($id: String) {
    file: mdx(id: { eq: $id }) {
      # childMdx {
      frontmatter {
        slug
        title
        headline
        intro
        article
        articleDate
        resource_name
        resource_type
        resource_consumption_method
        resource_download_url
        resource_promise
        my_first_name
        my_email
        my_email_link
        url_to_this_page
        email_url_this_page
        headerImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              breakpoints: [640, 1024, 1440, 1920]
            )
          }
        }
        fbTwitterImage: headerImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 630
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
        linkedInImage: headerImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 1200
              height: 627
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
        linkedInImageSml: headerImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 180
              height: 110
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
        # linkedInImageFeatured: headerImage {
        #   childImageSharp {
        #     fixed(height: 110, width: 180) {
        #       src
        #     }
        #   }
        # }
        # fbImageFeatured: headerImage {
        #   childImageSharp {
        #     fixed(height: 315, width: 600) {
        #       src
        #     }
        #   }
        # }
      }
      body
      # }
    }
  }
`
